<template>
  <div class="appLoader">
    <div :class="`myLoader`" :style="`--size:${size || '72px'}`">
      <iconic class="spinner" name="gspinner" />
      <iconic class="logo" name="lock1a" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["size"],
  computed: {
    message: function() {
      return this.$store.state.progress.message;
    },
  },
};
</script>

<style lang="scss">
.appLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $mpadding * 2;
  .myLoader {
    color: $primary_color;
    width: var(--size);
    height: var(--size);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    .spinner {
      font-size: var(--size);
    }

    svg.gspinner circle {
      stroke-width: 6% !important;
    }

    .logo {
      position: absolute;
      font-size: calc(var(--size) * 0.4);
    }

    .iconic {
      @include iconic();
    }
  }
}
</style>
